import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Image = ({ filename, alt, styles = {}, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        nodes {
          relativePath
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
          }
        }
      }
    }
  `);
  const image = getImage(
    data.images.nodes.find((node) => {
      return node.relativePath.includes(filename);
    })
  );
  if (!image) {
    return null;
  }
  return (
    <GatsbyImage
      {...rest}
      alt={alt}
      image={image}
      style={{ minHeight: '100%', minWidth: '100%', ...styles }}
    />
  );
};

export default Image;
